@import '../../../../../../styles/shared';

.lineOffset {
  margin: 36px 0 8px 0;
}

.ctaButton {
  margin-top: 24px;

  @include mq($from: desktop) {
    margin-top: 34px;
  }
}

.accordionHeading {
  font-size: 20px;
  line-height: 26px;

  @include mq($from: tablet) {
    font-size: 24px;
    line-height: 38px;
  }

  @include mq($from: desktop) {
    font-size: 28px;
    line-height: 42px;
  }
}

.accordionParagraph {
  font-size: 16px;
  line-height: 32px;
  margin-top: 16px;
}

.accordionItem {
  padding: 16px 0;

  @include mq($from: desktop) {
    padding: 32px 0;

    &:first-child {
      padding: 16px 0 32px 0;
    }
  }
}

@include hub-dark-theme() {
  .accordionItem {
    border-bottom: 1px solid var(--hub-accordion-module-dark-item-line-color);
  }
}

@include hub-light-theme() {
  .accordionItem {
    border-bottom: 1px solid var(--hub-accordion-module-light-item-line-color);
  }
}
